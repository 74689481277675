import React, { Autocomplete, CircularProgress, TextField } from "@mui/material";
import axios from "utils/axios"
import { useEffect, useState } from "react";
import { ErrorAlert } from "./Alerts";

type simpleGetProps = {
    setLoading: (loading: boolean) => void,
    setData: (data: any) => void,
    setError: (error: string) => void,
    url: string
}

const simpleGet = async ({ setLoading, setData, setError, url }: simpleGetProps) => {
    try {
        setLoading(true)
        const response = await axios.get(url);
        if (response.status !== 200) {
            setError("Failed to load data.")
            return
        }
        setData(response.data)
    } catch (e) {
        setError("Failed to load data.")
    } finally {
        setLoading(false)
    }
}


type DiagnosisCodePickerProps = {
    codes: any[],
    setCodes: (codes: any[]) => void
}

function DiagnosisCodePicker({ codes, setCodes }: DiagnosisCodePickerProps) {
    const [error, setError] = useState(null)
    const [diagnosisCodes, setDiagnosisCodes] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")

    useEffect(() => {
        // @ts-ignore
        simpleGet({ setLoading, setData: setDiagnosisCodes, setError, url: `/v1/diagnosisCodes?search=${search}` })
    }, [search])

    if (error) {
        return <ErrorAlert error={error} />
    }

    return (
        <Autocomplete
            multiple
            id="tags-standard"
            options={diagnosisCodes}
            onChange={(_, v) => setCodes(v)}
            onInputChange={(_, v) => setSearch(v)}
            disableListWrap
            filterOptions={(x) => x}
            value={codes}
            noOptionsText={"No codes found"}
            loading={loading}
            getOptionLabel={(option) =>
                option?.Code + ": " + option?.Description
            }
            isOptionEqualToValue={(option, value) =>
                option?.Code === value?.Code
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="ICD-10 Codes"
                    placeholder="Search for ICD-10 code"
                    required
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    )
}

export default DiagnosisCodePicker;
