import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

// reducer - state management
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";

// project import
import Loader from "components/Loader";
import axios from "utils/axios";

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    console.log(decoded);
    return true;
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
        console.log(state);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken =
                    Cookies.get("jot");
                    console.log(serviceToken);
                if (serviceToken && verifyToken(serviceToken)) {
                    const decoded = jwtDecode(serviceToken);
                    const _ = await axios.get("/v1/checkToken");
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: decoded,
                        },
                    });
                } else {
                    dispatch({
                        type: LOGOUT,
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT,
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        const response = await axios.post("/user/login", { email, password });
        const { Token } = response.data;
        const decoded = jwtDecode(Token);

        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: decoded,
            },
        });
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post("/api/account/register", {
            id,
            email,
            password,
            firstName,
            lastName,
        });
        let users = response.data;

        if (
            window.localStorage.getItem("users") !== undefined &&
            window.localStorage.getItem("users") !== null
        ) {
            const localUsers = window.localStorage.getItem("users");
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`,
                },
            ];
        }

        window.localStorage.setItem("users", JSON.stringify(users));
    };

    const logout = () => {
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async () => {};

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                resetPassword,
                updateProfile,
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node,
};

export default JWTContext;
