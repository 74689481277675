import React, { useEffect, useState } from "react";
import StyledStack from "./StyledStack";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ExclamationCircleTwoTone, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import axios from "utils/axios";
import { ErrorAlert } from "./Alerts";
import { useFormikContext } from "formik";
import dayjs from "dayjs";

function PatientInsuranceEligibility({ pi, setEligResponse, disabled, index }) {
    const [loading, setLoading] = useState(false);
    const [eligibilityResponses, setEligibilityResponses] = useState([])
    const [eligibilityError, setEligibilityError] = useState(null)

    const { setFieldValue, values } =
        useFormikContext();

    useEffect(() => {
        console.log('calling useeffect')
        if (!pi?.EligibilityResponses) {
            return
        }

        if (pi?.EligibilityResponses.length > 0 && eligibilityResponses.length === 0) {
            setEligibilityResponses(pi?.EligibilityResponses)
        }
        if (pi?.EligibilityResponses.length > 0) {
            setErrorFromResponse(pi.EligibilityResponses[pi.EligibilityResponses.length - 1])
        }
    }, [pi])

    console.log(values)

    const updateSubscriberFromResponse = (response) => {
        console.log("updating subscriber from eligibility", response)
        const first = response?.Subscriber?.FirstName
        const last = response?.Subscriber?.LastName
        const dob = response?.Subscriber?.DOB
        const effective = response?.DateEffective
        if (first) {
            console.log("updating firstname", first)
            setFieldValue(`PatientInsurances[${index}].Subscriber.FirstName`, first)
        }
        if (last) {
            console.log("updating lastname", last)
            setFieldValue(`PatientInsurances[${index}].Subscriber.LastName`, last)
        }
        if (dob) {
            console.log("updating dob", dob)

            setFieldValue(`PatientInsurances[${index}].Subscriber.DOB`, dayjs.utc(dob))
        }
        if (effective) {
            console.log("updating effective", effective)
            setFieldValue(`PatientInsurances[${index}].Effective`, dayjs(effective))
        }
    }

    const checkEligibility = () => {
        setLoading(true)
        const check = async () => {
            try {
                const response = await axios.post("/v1/eligibility", pi)
                if (response.status !== 200) {
                    setEligibilityError("Failed to check eligibility")
                    return
                }
                pi.EligibilityResponse = response.data
                setEligibilityResponses([...eligibilityResponses, response.data])
                setEligResponse(response.data)
                updateSubscriberFromResponse(response.data)
                setErrorFromResponse(response.data)
            } catch (e) {
                console.log(e)
                setEligibilityError("Failed to check eligibility")
            } finally {
                setLoading(false)
            }
        }

        check()
    }

    const setErrorFromResponse = (response) => {
        console.log("setting error from response", response)
        if (response?.AAAReasonDesc) {
            setEligibilityError(`${response?.AAAReasonDesc} - ${response?.AAAActionDesc}`)
        } else { setEligibilityError(null) }
    }

    const getEligible = (active) => {
        if (active) {
            return ["Active", getCheckIcon(active)]
        } else if (active === null) {
            return ["Not Checked", <></>]
        } else {
            return ["Inactive", <ExclamationCircleTwoTone style={{ fontSize: "24px" }} twoToneColor="red" />]
        }
    }

    const getCheckIcon = (value) => {
        if (value) {
            return <CheckCircleTwoTone style={{ fontSize: "24px" }} twoToneColor="#00c20a" />
        } else {
            return <CloseCircleTwoTone style={{ fontSize: "24px" }} twoToneColor="grey" />
        }

    }

    return (
        <StyledStack direction="column">
            <StyledStack>
                <LoadingButton
                    variant="contained"
                    onClick={checkEligibility}
                    disabled={disabled}
                    loading={loading}
                >Check Eligibility</LoadingButton>
                {eligibilityError && <ErrorAlert error={eligibilityError} />}
            </StyledStack>
            <StyledStack>
                {eligibilityResponses.length > 0 &&
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    Active
                                </TableCell>
                                <TableCell align="center">
                                    Last Checked
                                </TableCell>
                                <TableCell align="center">
                                    Medicare A
                                </TableCell>
                                <TableCell align="center">
                                    Medicare B
                                </TableCell>
                                <TableCell align="center">
                                    Medicare QMB
                                </TableCell>
                                <TableCell align="center">
                                    Medicare Railroad
                                </TableCell>
                                <TableCell align="center">
                                    Has Medicare Replacement
                                </TableCell>
                                <TableCell align="center">
                                    Has Medicaid Advantage
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {eligibilityResponses.map((eligibilityResponse, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{getEligible(eligibilityResponse?.Active)[1]}</TableCell>
                                    <TableCell align="center">{eligibilityResponse?.DateChecked.slice(0, 10)}</TableCell>
                                    <TableCell align="center">{getCheckIcon(eligibilityResponse?.MedicareA)}</TableCell>
                                    <TableCell align="center">{getCheckIcon(eligibilityResponse?.MedicareB)}</TableCell>
                                    <TableCell align="center">{getCheckIcon(eligibilityResponse?.MedicareQMB)}</TableCell>
                                    <TableCell align="center">{getCheckIcon(eligibilityResponse?.MedicareRailroad)}</TableCell>
                                    <TableCell align="center">{getCheckIcon(eligibilityResponse?.HasMedicareReplacement)}</TableCell>
                                    <TableCell align="center">{getCheckIcon(eligibilityResponse?.HasMedicaidAdvantage)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
            </StyledStack>
        </StyledStack>
    )

}

export default PatientInsuranceEligibility;
