import { Alert } from "@mui/material"


const style = { m: 2 }

function WarningAlert({ warning }) {
    if (!warning) {
        return <></>
    }

    return (
        <Alert sx={style} severity="warning">
            {warning}
        </Alert>
    )
}

function ErrorAlert({ error }) {
    if (!error) {
        return <></>
    }

    return (
        <Alert sx={style} severity="error">
            {error}
        </Alert>
    )
}

export { ErrorAlert, WarningAlert }
