import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);


export function FormatDateTime(d: string | undefined) {
    if (!d) {
        return "";
    }

    return dayjs(d).format('YYYY-MM-DD hh:mm:ss A');
}

export function FormatDate(d: string | undefined) {
    if (!d) {
        return "";
    }

    return dayjs(d).format('YYYY-MM-DD');
}

export function FormatDateUTC(d: string | undefined) {
    if (!d) {
        return "";
    }

    return dayjs.utc(d).format('YYYY-MM-DD');
}



function formatDate(date: string) {
    if (!date) {
        return ""
    }
    return date.slice(0, 10)

}

function formatAddress(address1: string, address2: string, city: string, state: string, zipcode: string) {
    let addressString = "";

    if (address1) {
        addressString += address1;
    }

    if (address2) {
        if (addressString) {
            addressString += ", " + address2;
        } else {
            addressString += address2;
        }
    }

    if (city) {
        if (addressString) {
            addressString += ", " + city;
        } else {
            addressString += city;
        }
    }

    if (state) {
        if (addressString) {
            addressString += ", " + state;
        } else {
            addressString += state;
        }
    }

    if (zipcode) {
        if (addressString) {
            addressString += " " + zipcode;
        } else {
            addressString += zipcode;
        }
    }

    return addressString;
}


export { formatAddress, formatDate };
