import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
} from "@mui/material";

function DetailDialog({ open, setOpen, title, subtext, children }) {
    console.log(children)

    return (
        <Dialog open={open} fullWidth maxWidth={"80%"}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{subtext}</DialogContentText>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DetailDialog;
