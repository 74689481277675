import { useState } from "react";
// material-ui
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import { TabPanel } from "@mui/lab";

// project import
import MainCard from "components/MainCard";
import ClientInsurances from "sections/mappings/insurances";
import ClientProcedures from "sections/mappings/procedures";

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

const Mappings = () => {
    const [value, setValue] = useState(0);

    return (
        <MainCard title="Client Mappings">
            <TabContext value={value}>
                <Tabs
                    value={value}
                    onChange={(_, v) => setValue(v)}
                    aria-label="basic tabs example"
                >
                    <Tab label="Insurances" {...a11yProps(0)} />
                    <Tab label="Procedures" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={0} index={0}>
                    <ClientInsurances />
                </TabPanel>
                <TabPanel value={1} index={1}>
                    <ClientProcedures />
                </TabPanel>
            </TabContext>
        </MainCard>
    );
};
export default Mappings;
