import React, { useState, useEffect, useMemo } from "react";
import axios from "utils/axios";

// project import
import MainCard from "components/MainCard";
import DataTable from "components/DataTable";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Input, Table, TableContainer, TableCell, TableRow, TableHead, CircularProgress, Box, TableBody, Typography, Tabs, Tab } from "@mui/material";
import { ErrorAlert } from "components/Alerts";
import { a11yProps } from "components/CustomTabs";
import CustomTabs from "components/CustomTabs";
import { useTabState } from "hooks/useTabState";
import { TabContext } from "@mui/lab";

// ==============================|| SAMPLE PAGE ||============================== //
//
function UploadButton({ disabled, handleClick }) {
    return (
        <Button variant="contained" disabled={disabled} onClick={handleClick}>Upload</Button>
    )
}

function FileUploadDialog({ setFile, handleSave, setOpen, open }) {
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Upload File</DialogTitle>
            <DialogContent>
                <DialogContentText>Select file that contains charges to ingest.</DialogContentText>
                <Input
                    required
                    type="file"
                    inputProps={{ accept: ".csv" }}
                    onChange={(e) => {
                        console.log("Setting file to ", e.target.files[0]);
                        setFile(e.target.files[0]);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => handleSave(() => setOpen(false))}>Save</Button>
            </DialogActions>
        </Dialog>
    )

}

function downloadBase64File(base64EncodedCsv, filename) {
    // Decode base64 string
    const csvContent = atob(base64EncodedCsv);

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    return url
}

function getFilenameSafeDateTime() {
    const now = new Date();

    // Format the date and time components to avoid invalid filename characters
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    // Combine the components in a filename-safe format
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}

function FileUploadStatusDialog({ uploadResponse, open, setOpen, error }) {

    console.log(error)
    const GetErrorFileLink = () => {
        if (!uploadResponse?.ErrorCSV) {
            return <></>
        }

        const downloadUrl = downloadBase64File(uploadResponse?.ErrorCSV)
        return <a href={downloadUrl} download={`errors-${getFilenameSafeDateTime()}.csv`} textContent={"test"}>Error File: errors.csv</a>
    }


    return (
        <Dialog open={open}>
            <DialogTitle>Import Status</DialogTitle>
            <DialogContent>
                {error && <ErrorAlert error={error} />}
                {!uploadResponse && !error ?
                    <Box alignItems="center" justifyContent="center" display="flex">
                        <CircularProgress color="success" />
                    </Box> :
                    <>

                        <p>Total Rows: {uploadResponse?.TotalRows}</p>
                        <p><b>Successfully Imported: {uploadResponse?.ImportSuccessful}</b></p>
                        <p>Parse Errors: {uploadResponse?.ParseErrors.length}</p>
                        <p>Import Errors: {uploadResponse?.ImportError}</p>
                        {GetErrorFileLink()}

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Row Number
                                        </TableCell>
                                        <TableCell>
                                            Error
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {uploadResponse?.ParseErrors.map((err) => (
                                        <TableRow>
                                            <TableCell>
                                                {err?.RowNum}
                                            </TableCell>
                                            <TableCell>
                                                {err?.Error}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )

}

const Ingest = () => {
    const [value, setValue] = useTabState(0);

    const tabs = [
        { label: "Ingest", component: <IngestTab /> },
        { label: "Errors", component: <ErrorsTab /> },
    ]

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Interfaces</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        {tabs.map((tab, index) => (
                            <Tab label={tab.label} {...a11yProps(index)} />
                        )
                        )}
                    </Tabs>
                </Box>
            </Box>
            {tabs.map((tab, index) => (
                <CustomTabs value={value} index={index}>
                    {tab.component}
                </CustomTabs>
            ))}
        </>
    )

}

const IngestTab = () => {
    const [processing, setProcessing] = useState(false);
    const [file, setFile] = useState();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState();
    const [uploadResponse, setUploadResponse] = useState();
    const [uploadStatusOpen, setUploadStatusOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (uploadStatusOpen) {
            return
        }

        setFile(null)
        setUploadResponse(null)
        setError(null)

    }, [uploadStatusOpen])

    useEffect(() => {
        const init = async () => {
            setProcessing(true);
            const response = await axios.get("/v1/ingest");
            setUploadedFiles(response.data);
            setProcessing(false);
        };

        init();
    }, [refresh]);

    const handleFileUpload = (callback) => {
        setProcessing(true)
        if (!file) {
            return
        }

        var data = new FormData();
        data.append("File", file);

        const upload = async () => {
            try {
                const response = await axios.post("/v1/ingest", data);
                setUploadResponse(response.data);
                setError(null)

            } catch (e) {
                setError("Upload failed: " + e.error)
            } finally {
                setProcessing(false)
                setRefresh(!refresh)
            }
        }
        callback()
        setUploadStatusOpen(true);

        upload()
    }

    const columns = useMemo(() => [
        { field: "CreatedAt", headerName: "Created At", width: 170 },
        { field: "Filename", headerName: "Name", width: 300 },
        { field: "TotalRows", headerName: "Total Rows", width: 100 },
        { field: "ImportSuccessful", headerName: "Sucessfully Imported", width: 150 },
        { field: "ImportErrors", headerName: "Import Failed", width: 100 },
        { field: "ParseErrors", headerName: "Parse Failed", width: 100 },
        { field: "CreatedBy", headerName: "Created By", width: 200, valueGetter: (params) => params.row.CreatedBy.Email },
    ], [])

    return (
        <MainCard title="Ingest" secondary={
            <UploadButton disabled={processing} handleClick={() => setOpen(true)} />
        }>
            <FileUploadDialog setFile={setFile} handleSave={handleFileUpload} open={open} setOpen={setOpen} />
            <FileUploadStatusDialog error={error} uploadResponse={uploadResponse} open={uploadStatusOpen} setOpen={setUploadStatusOpen} />
            <DataTable
                jsonKey={"datagrid-ingest"}
                data={uploadedFiles || []}
                columns={columns}
            />

        </MainCard>
    )
};

const ErrorsTab = () => {
    const [errors, setErrors] = useState([]);
    const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        const init = async () => {
            const response = await axios.get("/v1/ingest/errors");
            setErrors(response.data);
        };

        init();
    }, [refresh]);

    const handleDownload = async (id, filename) => {
        // download file 
        const response = await axios.get(`/v1/attachments?id=${id}`, { responseType: 'blob' })
        if (response.data) {
            const blob = response.data
            const blobUrl = window.URL.createObjectURL(blob);
            // Create a link element
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', filename); // Set the file name for download
            // Append to the document and trigger the download
            document.body.appendChild(link);
            link.click();
            // Clean up
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);

        }
    }

    const columns = useMemo(() => [
        { field: "CreatedAt", headerName: "Created At", width: 170 },
        {
            field: "Attachment", headerName: "Name", width: 300,
            valueGetter: (params) => params.row.Attachment.Name,
            renderCell: (params) => <Button onClick={() => handleDownload(params.row.AttachmentID, params.row.Attachment.Name)} >{params.row.Attachment.Name}</Button>
        },
        { field: "Error", headerName: "Error", width: 1000 },
    ], [])

    return (
        <MainCard title="Ingest">
            <DataTable
                jsonKey={"datagrid-ingest-errors"}
                data={errors || []}
                columns={columns}
            />

        </MainCard>
    )
}

export default Ingest;
