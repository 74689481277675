import React, { useEffect, useState } from "react";
import axios from "utils/axios";
import { Note } from "types";
import { Card, Stack } from "@mui/material";
import { ErrorAlert } from "./Alerts";
import { formatDate } from "utils/formatting";

function ViewNotes({ accessionId, refresh }: { accessionId: number, refresh: boolean }) {
    const [notes, setNotes] = useState<Note[]>([])
    const [error, setError] = useState<null | string>()

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axios.get(`/v1/accessions/${accessionId}/notes`)
                if (response.status === 200) {
                    setNotes(response.data)
                    setError(null)
                } else {
                    throw new Error("failed to save note")
                }
            } catch {
                setError("Failed to save note")
            }
        }

        init()

    }, [accessionId, refresh])

    return (
        <>
            <ErrorAlert error={error} />
            <Stack direction="column" spacing={2}>
                {notes.length === 0 && <p>No notes have been added.</p>}
                {notes.map((note: Note) => (
                    <Card sx={{ p: 2, background: "#cedae0" }} raised={true} >
                        <p>{formatDate(note.CreatedAt)} - {note.AddedBy.FirstName} {note.AddedBy.LastName} - {note.AddedBy.Email}</p>
                        <p>{note.Note}</p>
                    </Card>
                ))}
            </Stack>
        </>
    )

}

export default ViewNotes;
