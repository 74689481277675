import axios from "utils/axios";
import React, { useEffect, useState } from "react";
import PatientForm from "./PatientForm";
import { Stack, Button, Box, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Chip } from "@mui/material";
import CustomTabs from "./AccessionDetailTabs";
import AccessionSummary from "./AccessionSummary";
import StyledStack from "./StyledStack";
import { FormatDateUTC } from "utils/formatting";
import { Accession } from "types";
import { ErrorAlert } from "./Alerts";
import AddNote from "./AddNote";
import ViewNotes from "./AccessionDetailNotes";
import AccessionHistory from "./AccessionHistory";


function GetStatusChip({ row, size = "small" }: { row: Accession | null, size?: any }) {
    // @ts-ignore
    const currentStage = row?.CurrentStage
    if (!currentStage) {
        return <></>
    }

    // @ts-ignore
    if (row.CurrentStage?.ErrorID !== 0 && row.CurrentStage?.ErrorID !== null) {
        // @ts-ignore
        return <Chip label={row.CurrentStage?.Error?.Description} color="error" size={size} />;
        // @ts-ignore
    } else if (row.CurrentStage?.ErrorID !== 0 && row.CurrentStage?.ErrorID !== null && row.CurrentStage.Processed) {
        return <Chip label={"Complete w/ Errors"} color="warning" size={size} />;
        // @ts-ignore
    } else if (row.CurrentStage.Processed) {
        return <Chip label={"Complete"} color="success" size={size} />;
        // @ts-ignore
    } else if (row.CurrentStage.Bypassed) {
        return <Chip label={"Bypassed"} color="warning" size={size} />;
    } else {
        return <Chip label={"Pending"} color="primary" size={size} />;
    }

};


function AccessionDetail({ id }: { id: number }) {
    const [accession, setAccession] = useState<Accession | null>(null)
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [addNote, setAddNote] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [tabValue, setTabValue] = useState(0)
    const tabs = [
        { label: "Accession", component: <AccessionSummary accession={accession} /> },
        { label: "Patient", component: <PatientForm id={accession?.PatientID} /> },
        { label: "History", component: <AccessionHistory accessionId={id} patientId={accession?.PatientID} refresh={refresh} /> },
        { label: "Notes", component: <ViewNotes accessionId={id} refresh={refresh} /> },
    ];

    useEffect(() => { if (!addNote) { setRefresh(!refresh) } }, [addNote])

    useEffect(() => {
        if (!id) {
            return
        }

        const init = async () => {
            setLoading(true)
            try {
                const response = await axios.get(`/v1/accessions/${id}`)
                setAccession(response.data)
            } catch (e) {
                setError("Failed to load accession")
            } finally {
                setLoading(false)
            }
        }

        init()
    }, [id, refresh])

    const handleAssignment = async () => {
        try {
            const response = await axios.post(`/v1/accessions/${id}/clientAssignment`)
            if (response.status === 200) {
                setRefresh(!refresh)
            }
        } catch (e) {
            console.log(e)
            setError("Failed to update assignment")
        }
    }

    return (
        <Stack direction="column">
            <AddNote open={addNote} setOpen={setAddNote} id={id} />
            <Stack direction="row" justifyContent={"space-between"}>
                <StyledStack>
                    <TableContainer>
                        <Table>
                            <TableRow>
                                <TableBody>
                                    <TableCell><b>Accession</b></TableCell>
                                    <TableCell>{accession?.Code}</TableCell>
                                    <TableCell><b>Date of Service</b></TableCell>
                                    <TableCell>{FormatDateUTC(accession?.DateOfService)}</TableCell>
                                    <TableCell><b>Status</b></TableCell>
                                    <TableCell><GetStatusChip row={accession} size="normal" /></TableCell>
                                    <TableCell><b>Assigned: {accession?.AssignedToClient ? "Client" : "SBS"}</b></TableCell>
                                </TableBody>
                            </TableRow>
                        </Table>
                    </TableContainer>

                </StyledStack>
                <StyledStack>
                    {accession?.AssignedToClient ?
                        <Button size="small" variant="contained" color="primary" onClick={handleAssignment}>Assign to SBS</Button> :
                        <Button size="small" variant="contained" color="primary" onClick={handleAssignment}>Assign to Client</Button>}
                    <Button size="small" variant="contained" color="secondary">Hold</Button>
                    <Button size="small" variant="contained" color="primary" onClick={() => setAddNote(true)}>Add Note</Button>
                </StyledStack>
            </Stack>
            <CustomTabs value={tabValue} setValue={setTabValue} tabs={tabs} />
            <ErrorAlert error={error} />
        </Stack >
    )
}

export default AccessionDetail;
