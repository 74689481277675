import { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    TableContainer,
} from "@mui/material";
import { DatabaseTwoTone } from "@ant-design/icons";

const ClientsMappingTable = ({clients, setClientId}) => {
    const [selected, setSelected] = useState([]);

    const isChecked = (rowNum) => {
        selected.includes(rowNum);
    };

    const handleCheck = (event, row) => {
        if (event.target.checked) {
            setSelected([...selected, row.ID]);
        } else {
            let c = [...selected];
            let index = c.indexOf(row.ID);
            c.splice(index, 1);
            setSelected(c);
        }
    };
    return (
        <>
            <TableContainer>
                <Table size="small" sx={{ mt: 2 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>View Mappings</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clients.map((row) => {
                            return (
                                <TableRow
                                    key={row.ID}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>
                                        <Checkbox
                                            checked={isChecked(row.ID)}
                                            onChange={(e) =>
                                                handleCheck(e, row)
                                            }
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{row.ID}</TableCell>
                                    <TableCell>{row.Code}</TableCell>
                                    <TableCell>{row.Name}</TableCell>
                                    <TableCell>
                                        <DatabaseTwoTone
                                            style={{ fontSize: "25px" }}
                                            onClick={() =>
                                                setClientId(row.ID)
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ClientsMappingTable;
