import { DataGridPro } from "@mui/x-data-grid-pro";
import { useMemo } from "react";

const ProceduresTable = ({ rows, selected, setSelected, disableCheckboxes }) => {

    const columns = useMemo(() => [
        { field: "ID", headerName: "ID", width: 70 },
        { field: "Code", headerName: "Code", width: 95 },
        { field: "Name", headerName: "Name", width: 150 },
        { field: "CPTCode", headerName: "CPT Code", width: 100 },
        { field: "AMDCode", headerName: "AMD Code", width: 100 },
        { field: "Explode", headerName: "Explodes", width: 95 },
        { field: "CPTModifiers", headerName: "Modifiers", width: 150, valueGetter: (params) => params.row.CPTModifiers.map((modifier) => modifier.Code).join(", ") },
    ], []);

    return (
        <>
            <DataGridPro
                getRowId={(row) => row.ID}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "ID", sort: "asc" }],
                    },
                }}
                density="compact"
                sx={{
                    height: "1000px",
                }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onRowSelectionModelChange={setSelected}
                rowSelectionModel={selected}
                pageSize={100}
                checkboxSelection={!disableCheckboxes}
                rows={rows}
                columns={columns}
            />
        </>
    );
};

export default ProceduresTable;
