import { Checkbox, FormControlLabel, MenuItem, Stack, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
    Radio,
    RadioGroup,
    Autocomplete,
    Box,
    Divider,
    IconButton
} from "@mui/material";
import { CloseCircleOutlined, PlusCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "utils/axios";
import StyledStack from "./StyledStack";
import CustomTextField from "./CustomTextField";
import { financialClassIndex } from "utils/xrefs";
import PatientInsuranceEligibility from "./PatientInsuranceEligibility";
import { FormContext } from "./PatientForm";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


function InsuranceFields({ pi, index, insurances }) {
    const edit = useContext(FormContext);

    const { setFieldValue, values, touched, setTouched } =
        useFormikContext();

    if (!pi) {
        return;
    }

    const insuranceDefaultValues = {
        Insurance: {},
        InsuranceID: null,
        Effective: null,
        Relationship: "Self",
        Discontinue: null,
        MemberID: "",
        GroupID: "",
        Enabled: true,
        Approved: true,
        PatientID: values?.ID,
        Eligible: null,
        EligibilityLastChecked: null,
        Subscriber: {
            FirstName: "",
            LastName: "",
            DOB: null
        },
        Primary: true,
        Secondary: false,
    };

    const copyPatientToSubscriber = () => {
        setFieldValue(`PatientInsurances[${index}].Subscriber.FirstName`, values?.FirstName)
        setTouched({ ...touched, [`PatientInsurances[${index}].Subscriber.FirstName`]: true })
        setFieldValue(`PatientInsurances[${index}].Subscriber.LastName`, values?.LastName)
        setTouched({ ...touched, [`PatientInsurances[${index}].Subscriber.LastName`]: true })
        setFieldValue(`PatientInsurances[${index}].Subscriber.DOB`, values?.DOB)
        setTouched({ ...touched, [`PatientInsurances[${index}].Subscriber.DOB`]: true })
    }



    const handleAddInsuranceButton = () => {
        let newIns = values?.PatientInsurances.slice();
        newIns.push(insuranceDefaultValues);

        setFieldValue('PatientInsurances', newIns);
    };

    const handleRemoveInsuranceButton = () => {
        if (values?.PatientInsurances.length === 1) {
            return;
        }
        // remove patient_insurance from state map
        let newIns = values?.PatientInsurances.slice();
        newIns.splice(index, 1);

        setFieldValue('PatientInsurances', newIns);
    };


    return (
        <>
            <Divider variant="middle" />
            <RadioGroup
                required
                value={String(pi.Primary)}
                name="Primary"
                sx={{ display: "block" }}
                onChange={(_, value) => {
                    setFieldValue(`PatientInsurances[${index}].Primary`, value === "true");
                    setFieldValue(`PatientInsurances[${index}].Secondary`, value === "false");
                }}
            >
                <FormControlLabel
                    value="true"
                    control={<Radio
                        disabled={!edit}
                    />}
                    label="Primary"
                />
                <FormControlLabel
                    value="false"
                    control={<Radio
                        disabled={!edit}
                    />}
                    label="Secondary"
                />
            </RadioGroup>
            <StyledStack>
                <Autocomplete
                    required
                    name="Insurance"
                    disablePortal
                    options={insurances}
                    disabled={!edit}
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value?.ID
                    }
                    groupBy={(option) =>
                        financialClassIndex[option.FinancialClass]
                    }
                    value={pi.Payer}
                    onChange={(_, value) => {
                        setFieldValue(`PatientInsurances[${index}].Payer`, value);
                        setFieldValue(`PatientInsurances[${index}].PayerID`, value.ID);
                    }}
                    sx={{ width: "400px" }}
                    renderInput={(params) => (
                        <TextField {...params} required label="Insurance" />
                    )}
                />
                <CustomTextField
                    required
                    name="MemberID"
                    disabled={!edit}
                    value={pi.MemberID}
                    onChange={(e) => {
                        setFieldValue(`PatientInsurances[${index}].MemberID`, e.target.value)
                    }}
                    label="Member ID"
                />
                <TextField
                    value={pi.GroupID}
                    disabled={!edit}
                    onChange={(e) => {
                        setFieldValue(`PatientInsurances[${index}].GroupID`, e.target.value)
                    }}
                    name="GroupID"
                    label="Group ID"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        disableFuture
                        timezone="utc"
                        label="Date Effective"
                        name="Effective"
                        disabled={!edit}
                        value={values?.PatientInsurances[index].Effective}
                        onChange={(e, _) => {
                            if (e === null || e.toString() === "Invalid Date") {
                                setFieldValue(`PatientInsurances[${index}].Effective`, null)
                                return
                            }
                            const formattedDate = dayjs(e).format('YYYY-MM-DD');
                            console.log('setting effective', dayjs.utc(formattedDate));
                            setFieldValue(`PatientInsurances[${index}].Effective`, dayjs.utc(formattedDate))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                helperText="mm/dd/yyyy"
                            />
                        )}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        timezone="utc"
                        name="DateDiscontinue"
                        label="Date Discontinue"
                        value={pi.Discontinue}
                        disabled={!edit}
                        onChange={(e, _) => {
                            if (e === null || e.toString() === "Invalid Date") {
                                setFieldValue(`PatientInsurances[${index}].Discontinue`, null)
                                return
                            }
                            const formattedDate = dayjs(e).format('YYYY-MM-DD');
                            console.log('setting discontinue', dayjs.utc(formattedDate));
                            setFieldValue(`PatientInsurances[${index}].Discontinue`, dayjs.utc(formattedDate))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                helperText="mm/dd/yyyy"
                            />
                        )}
                    />
                </LocalizationProvider>
                <IconButton
                    color="primary"
                    sx={{ float: "right" }}
                    disabled={!edit}
                    onClick={handleAddInsuranceButton}
                >
                    <PlusCircleOutlined />
                </IconButton>
                <IconButton
                    color="error"
                    disabled={!edit}
                    sx={{ float: "right" }}
                    onClick={handleRemoveInsuranceButton}
                >
                    <CloseCircleOutlined />
                </IconButton>
            </StyledStack>
            <StyledStack>
                <CustomTextField
                    required
                    name="Subscriber.FirstName"
                    disabled={!edit}
                    InputLabelProps={{ shrink: Boolean(pi.Subscriber?.FirstName) }}
                    value={values.PatientInsurances[index]?.Subscriber?.FirstName}
                    onChange={(e) => {
                        setFieldValue(`PatientInsurances[${index}].Subscriber.FirstName`, e.target.value)
                    }}
                    label="Subscriber First Name"
                />
                <CustomTextField
                    required
                    name="Subscriber.LastName"
                    InputLabelProps={{ shrink: Boolean(pi.Subscriber?.LastName) }}
                    disabled={!edit}
                    value={values.PatientInsurances[index]?.Subscriber?.LastName}
                    onChange={(e) => {
                        setFieldValue(`PatientInsurances[${index}].Subscriber.LastName`, e.target.value)
                    }}
                    label="Subscriber Last Name"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        name="Subscriber.DOB"
                        label="Subscriber Date of Birth *"
                        timezone="utc"
                        InputLabelProps={{ shrink: Boolean(pi.Subscriber?.DOB) }}
                        value={values.PatientInsurances[index]?.Subscriber?.DOB}
                        disabled={!edit}
                        required
                        onChange={(e, _) => {
                            if (e === null || e.toString() === "Invalid Date") {
                                return
                            }
                            const formattedDate = dayjs(e).format('YYYY-MM-DD');
                            console.log('setting dob', dayjs.utc(formattedDate));
                            setFieldValue(`PatientInsurances[${index}].Subscriber.DOB`, dayjs.utc(formattedDate))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                helperText="mm/dd/yyyy"
                                required
                            />
                        )}
                    />
                </LocalizationProvider>
                <TextField
                    required
                    select
                    name={`PatientInsurances[${index}].Relationship`}
                    label="Relationship to Subscriber"
                    disabled={!edit}
                    variant="outlined"
                    sx={{ width: "200px" }}
                    value={pi.Relationship}
                    onChange={(e) => {
                        setFieldValue(`PatientInsurances[${index}].Relationship`, e.target.value);
                    }}
                >
                    <MenuItem value="Self">Self</MenuItem>
                    <MenuItem value="Spouse">Spouse</MenuItem>
                    <MenuItem value="Child">Child</MenuItem>
                    <MenuItem value="Mother">Mother</MenuItem>
                    <MenuItem value="Father">Father</MenuItem>
                    <MenuItem value="Stepparent">Stepparent</MenuItem>
                    <MenuItem value="Stepchild">Stepchild</MenuItem>
                </TextField>
                <IconButton disabled={!edit} >
                    <CopyOutlined onClick={copyPatientToSubscriber} />
                </IconButton>
                <FormControlLabel control={
                    <Checkbox checked={pi?.Enabled}
                        onChange={(_, v) => setFieldValue(`PatientInsurances[${index}].Enabled`, v)} />}
                    label="Enabled"
                    disabled={!edit} />
            </StyledStack>
        </>
    );
};


export default function PatientInsuranceFields({
    patientInsurances,
    patient,
    eligResponses,
    setEligResponses
}) {
    const [insurances, setInsurances] = useState([]);
    const edit = useContext(FormContext);

    useEffect(() => {
        const init = async () => {
            const response = await axios.get("/v1/payers")
            setInsurances(response.data);
        }
        init()
    }, []);

    if (!patientInsurances) {
        return <></>
    }

    return (
        <Box>
            <StyledStack direction="column">
                {patientInsurances.map((pi, i) => {
                    let p = { ...patient }
                    delete p.PatientInsurances
                    pi.Patient = { ...p };
                    return (
                        <>
                            <InsuranceFields pi={pi} key={i} index={i} insurances={insurances} />
                            <PatientInsuranceEligibility
                                index={i}
                                key={i + 1 * 1000}
                                pi={pi}
                                disabled={!edit}
                                setEligResponse={(e) => setEligResponses({ ...eligResponses, [i]: e })} />
                        </>
                    )
                })}
            </StyledStack>
        </Box>
    );
}

