import React, { useState, useEffect, useMemo, ReactNode, useCallback } from "react";
import axios from "utils/axios";

import {
    Box,
    Stack,
    TextField,
    Autocomplete,
    Button,
} from "@mui/material";

import useAuth from 'hooks/useAuth';
import ClientsMappingTable from "components/ClientMappingTable";
import { GridToolbar, GridActionsCellItem, GridColDef, GridRowParams, useGridApiRef, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import { EditTwoTone } from "@ant-design/icons";
import { Delete } from "@mui/icons-material";
import { Payer, ClientPayer, Client, User } from "types";
import { ErrorAlert } from "components/Alerts";
import { StyledDataGrid } from "themes/overrides/DataGridPro";

const ClientInsurances = () => {
    const [clients, setClients] = useState<Client[]>([
        {
            Name: "",
            ID: 1,
        },
    ]);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [payers, setPayers] = useState<Payer[]>([]);
    const [viewClient, setViewClient] = useState<number | null>(null);
    const [clientPayers, setClientPayers] = useState<ClientPayer[]>([]);
    const [newMappingError, setNewMappingError] = useState({});
    const [newMapping, setNewMapping] = useState<ClientPayer>({
        Code: "",
        Name: "",
        Payer: null,
    });
    const apiRef = useGridApiRef()
    const { user }: { user: User } = useAuth();
    console.log('viewClient', viewClient)

    const saveEdit = (newRow: any, oldRow: any) => {
        console.log('oldValues', oldRow)
        console.log('newValues', newRow)
        newRow.PayerID = newRow.Payer?.ID
        const updateClientPayer = async () => {
            try {
                const response = await axios.post(
                    `/v1/clients/${viewClient}/payers/${newRow.ID}`,
                    newRow
                );
                if (response.status >= 200 && response.status < 300) {
                    setError(null);
                    setRefresh(!refresh);
                }
                return newRow
            } catch (error) {
                console.log(error)
                setError("Failed to update row");
            }
        };

        return updateClientPayer();
    };

    useEffect(() => {
        if (viewClient === null || !Number.isInteger(Number(viewClient))) {
            return;
        }

        const getClientPayers = async () => {
            let response = await axios.get(`/v1/clients/${viewClient}/payers`);
            setClientPayers(response.data);
        };

        getClientPayers();
    }, [viewClient, refresh]);

    useEffect(() => {
        console.log("updating view")
        if (user?.ClientFilter) {
            setViewClient(user.ClientFilter)
        }
    }, [user])

    useEffect(() => {
        const init = async () => {
            let response = await axios.get("/v1/clients");
            setClients(response.data);
            response = await axios.get("/v1/payers");
            setPayers(response.data);
        };

        init();
    }, [refresh]);


    const addMapping = () => {
        if (newMapping.Code === "") {
            setNewMappingError({
                ...newMappingError,
                Code: "Code is required",
            });
            return;
        } else if (newMapping.Name === "") {
            setNewMappingError({
                ...newMappingError,
                Name: "Name is required",
            });
            return;
        }

        setNewMappingError({});

        const add = async () => {
            try {
                const response = await axios.post(
                    `/v1/clients/${viewClient}/payers`,
                    newMapping
                );
                if (response.status >= 200 && response.status < 300) {
                    // let cP = clientPayers;
                    // cP.push(response)
                    // setClientPayers([...cP])
                    setError(null);
                    setRefresh(!refresh);
                    setNewMapping({
                        ...{},
                        Code: "",
                        Name: "",
                        Payer: null,
                    });
                }
            } catch (error) {
                console.log(error)
                setError("Failed to add row");
            }
        };
        add();
    };

    const deleteRow = async (id: number) => {
        try {
            const response = await axios.delete(
                `/v1/clients/${viewClient}/payers/${id}`
            );
            if (response.status >= 200 && response.status < 300) {
                setRefresh(!refresh);
            }
        } catch (error) {
            setError("Failed to add row");
        }

    }

    const RenderValueCell = useCallback((params: GridRenderEditCellParams): ReactNode => {
        const { id, value, field } = params;
        const handleValueChange = (
            _: React.SyntheticEvent<Element, Event>,
            newValue: Payer | null,
        ) => {
            console.log('newPayer value', newValue);
            apiRef.current.setEditCellValue({ id, field, value: newValue });
            if (newValue) {
                const payerId = newValue?.ID
                apiRef.current.setEditCellValue({ id, field: "PayerID", value: payerId });
            }
        };

        return (
            <Autocomplete
                options={payers}
                getOptionLabel={(option) =>
                    option.Name
                }
                value={value}
                sx={{ minWidth: "200px", width: "100%" }}
                isOptionEqualToValue={(
                    option,
                    value
                ) => option.ID === value.ID}
                onChange={handleValueChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                    />
                )}
            />
        )
    }, [apiRef, payers]);

    const getActions = useCallback((params: GridRowParams) => {
        console.log(apiRef.current.getRowMode(params.id))
        return [<GridActionsCellItem icon={<EditTwoTone disabled={apiRef.current.getRowMode(params.id) === "view"} />} onClick={() => apiRef.current.startRowEditMode({ id: params.id })} label="Edit" />,
        <GridActionsCellItem icon={<Delete />} onClick={() => deleteRow(Number(params.id))} label="Delete" />
        ]
    }, [apiRef, deleteRow])

    const columns: GridColDef[] = useMemo(() => [
        { field: "Code", headerName: "Code", editable: true, width: 100 },
        { field: "Name", headerName: "Name", editable: true, width: 200 },
        { field: "Payer", headerName: "Nexum Payer", width: 300, valueFormatter: (params) => params?.value?.Name, editable: true, renderEditCell: RenderValueCell },
        { field: "actions", type: "actions", getActions: getActions },
    ], [RenderValueCell, getActions])

    const PayerMappingDataTable = () => {
        return (
            <StyledDataGrid
                getRowId={(row) => row.ID}
                apiRef={apiRef}
                columns={columns}
                rows={clientPayers}
                editMode="row"
                processRowUpdate={saveEdit}
                onProcessRowUpdateError={(e) => { console.log(e); setError("Failed to update row") }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                density="compact"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        )
    }

    if (viewClient !== null) {
        return <>
            <Stack direction="row" spacing={2}>
                <TextField
                    label="Code"
                    value={newMapping?.Code}
                    onChange={(e) =>
                        setNewMapping({
                            ...newMapping,
                            Code: e.target.value,
                        })
                    }
                />
                <TextField
                    label="Name"
                    value={newMapping?.Name}
                    onChange={(e) =>
                        setNewMapping({
                            ...newMapping,
                            Name: e.target.value,
                        })
                    }
                />
                <Autocomplete
                    options={payers}
                    getOptionLabel={(option) =>
                        option.Name
                    }
                    sx={{ minWidth: "200px" }}
                    value={newMapping?.Payer}
                    onChange={(_, newValue) =>
                        setNewMapping({
                            ...newMapping,
                            PayerID: newValue?.ID || null,
                            Payer: newValue,
                        })
                    }
                    renderInput={(params) => (
                        <TextField
                            label="Nexum Payer"
                            {...params} />
                    )}
                />
                <Button variant="contained" color="primary" onClick={() => addMapping()}>Add</Button>
            </Stack>
            <Box height={`calc(100vh - 425px)`}>
                <PayerMappingDataTable />
            </Box>
            <ErrorAlert error={error} />
        </>;
    }

    return <ClientsMappingTable clients={clients} setClientId={setViewClient} />
};

export default ClientInsurances;
