
// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    BorderOutlined,
    BoxPlotOutlined,
    ChromeOutlined,
    DeploymentUnitOutlined,
    GatewayOutlined,
    MenuUnfoldOutlined,
    QuestionOutlined,
    SmileOutlined,
    StopOutlined,
    ClearOutlined,
    DingdingOutlined,
    FunnelPlotOutlined,
    AreaChartOutlined,
    ImportOutlined
} from "@ant-design/icons";

// icons
const icons = {
    ChromeOutlined,
    MenuUnfoldOutlined,
    BoxPlotOutlined,
    StopOutlined,
    BorderOutlined,
    SmileOutlined,
    GatewayOutlined,
    QuestionOutlined,
    DeploymentUnitOutlined,
    DingdingOutlined,
    ClearOutlined,
    FunnelPlotOutlined,
    AreaChartOutlined,
    ImportOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
// Update name mapping in en.json

const home = {
    id: "home",
    title: <FormattedMessage id="home" />,
    type: "group",
    children: [
        {
            id: "client",
            title: <FormattedMessage id="client" />,
            type: "dropdown",
        },
        {
            id: "dashboard",
            title: <FormattedMessage id="dashboard" />,
            type: "item",
            url: "/dashboard",
            icon: icons.AreaChartOutlined,
        },
        {
            id: "ingest",
            title: <FormattedMessage id="ingest" />,
            type: "item",
            url: "/ingest",
            icon: icons.ImportOutlined,
        },
    ],
};

export default home;
