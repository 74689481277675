import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/system";
import {
    InputLabel,
    Alert,
    Checkbox,
    FormControlLabel,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    TextField,
    Autocomplete,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "utils/axios";
import CustomTextField from "./CustomTextField";
import { LoadingButton } from "@mui/lab";
import { FeeScheduleNames } from "constants";
import ProceduresTable from "./ClientProceduresTable";

const ProcedureForm = ({ clientId, procedureId, back }) => {
    const [procedure, setProcedure] = useState({
        Name: "",
        Code: "",
        CPTCode: "",
        AMDCode: "",
        Explode: false,
        ExplodesTo: [],
        CPTModifiers: [],
    });
    const [feeSchedules, setFeeSchedules] = useState([]);
    const [modifiers, setModifiers] = useState([]);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [cptDetails, setCPTDetails] = useState(null);
    const [procedures, setProcedures] = useState([]);
    const [explodesTo, setExplodesTo] = useState([]);

    const validationSchema = yup.object();

    useEffect(() => {
        const init = async () => {
            const response = await axios.get(`/v1/modifiers`)
            setModifiers(response.data)
        }

        try {
            init()
        } catch (e) {
            console.log(e)
        }
    }, [])

    useEffect(() => {
        const getFeeSchedules = async () => {
            const response = await axios.get(
                `/v1/clients/${clientId}/feeSchedules`
            );
            setFeeSchedules(response.data);
        };

        getFeeSchedules();
    }, [clientId]);

    useEffect(() => {
        const getProcedures = async () => {
            try {
                const response = await axios.get(
                    `/v1/clients/${clientId}/procedures`
                );
                if (response.status === 200) {
                    setProcedures(response.data.filter((proc) => !proc.Explode && proc.ID !== procedureId))
                } else {
                    setError("Failed to load procedures");
                }
            } catch (e) {
                setError("Failed to load procedures");
            }
        };

        getProcedures();
    }, [clientId]);

    useEffect(() => {
        if (!procedureId) {
            return
        }

        const getProcedure = async () => {
            try {
                const response = await axios.get(
                    `/v1/clients/${clientId}/procedures/${procedureId}`
                );
                if (response.status === 200) {
                    setProcedure(response.data);
                    const explodingIds = response.data.ExplodesTo.map((proc) => proc.ID);
                    setExplodesTo(explodingIds || []);
                } else {
                    setError("Failed to load procedure");
                }
            } catch (e) {
                setError("Failed to load procedure");
            }
        };

        getProcedure();
    }, [clientId, procedureId]);

    console.log(explodesTo);

    const handleSubmit = (values) => {
        const postValues = { ...values };
        setSaving(true);
        console.log(values);
        console.log(explodesTo);
        const fees = [];

        // for (let [f, v] of Object.entries(values.FeeSchedules)) {
        // const feeSchedID = f.slice(2);
        // fees.push({
        // ID: Number(feeSchedID),
        // Fee: v,
        // });
        // }

        let url = `/v1/clients/${clientId}/procedures`;
        if (procedureId) {
            url += `/${procedureId}`;
        }

        postValues.FeeSchedules = fees;
        postValues.ExplodesTo = explodesTo;

        const addProcedure = async () => {
            try {
                const response = await axios.post(
                    url,
                    postValues
                );
                if (response.status < 300) {
                    back();
                } else {
                    setError("Failed to save procedure");
                }
            } catch {
                setError("Failed to save procedure");
            } finally {
                setSaving(false);
            }
        };

        addProcedure();
    };

    const updateCPT = (e) => {
        const CPTCode = e.target.value;
        console.log(e);
        if (CPTCode.length !== 5) {
            setCPTDetails(null);
            return;
        }

        const getAllowable = async () => {
            const response = await axios.get("/v1/cmsAllowable", {
                params: { cpt_code: CPTCode },
            });
            if (response.status === 200) {
                setCPTDetails(response.data);
            } else {
                setCPTDetails(null);
            }
        };

        getAllowable();
    };

    const cptTable = (setFieldValue) => {
        return (
            <>
                <InputLabel>CMS Allowable Details</InputLabel>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>CPT Code</TableCell>
                                <TableCell>Allowable</TableCell>
                                <TableCell>Long Description</TableCell>
                                <TableCell>Copy To Fee Schedules</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{cptDetails?.HCPCS}</TableCell>
                                <TableCell>$ {cptDetails?.Rate}</TableCell>
                                <TableCell>
                                    {cptDetails?.ExtendedLongDesc}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            let newRates = {};
                                            for (let f of feeSchedules) {
                                                newRates[`id${f.ID}`] =
                                                    cptDetails.Rate;
                                            }
                                            setFieldValue(
                                                "FeeSchedules",
                                                newRates
                                            );
                                        }}
                                    >
                                        Copy
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    };

    const feeScheduleFields = (handleChange, errors, touched, values) => {
        return (
            <>
                {feeSchedules.map((feeSchedule, index) => {
                    console.log(
                        values?.FeeSchedules?.[`id${feeSchedule.ID}`]
                    );
                    return (
                        <TextField
                            key={index}
                            name={`FeeSchedules.id${feeSchedule.ID}`}
                            label={
                                feeSchedule.Type === 1
                                    ? `${FeeScheduleNames[feeSchedule.Type]} ${feeSchedule?.ClientAccount?.Name || ""
                                    }`
                                    : FeeScheduleNames[feeSchedule.Type]
                            }
                            required={true}
                            type="number"
                            inputProps={{
                                type: "number",
                                min: 0,
                                step: 0.01,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: "$",
                            }}
                            helperText={
                                touched?.FeeSchedules?.[`id${feeSchedule.ID}`]
                            }
                            value={
                                values?.FeeSchedules?.[`id${feeSchedule.ID}`]
                            }
                            onChange={(event) => {
                                // Only allow numeric input
                                if (
                                    !event.target.value ||
                                    /^\d*\.?\d*$/.test(event.target.value)
                                ) {
                                    handleChange(event);
                                }
                            }}
                            error={
                                touched?.FeeSchedules?.[
                                `id${feeSchedule.ID}`
                                ] &&
                                Boolean(
                                    errors.FeeSchedules?.[`id${feeSchedule.ID}`]
                                )
                            }
                        />
                    );
                })}
            </>
        );
    };

    return (
        <Box>
            <Formik
                initialValues={procedure}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    handleChange,
                    errors,
                    touched,
                    handleBlur,
                    setFieldValue,
                    isValid,
                }) => (
                    <Form>
                        {console.log(values)}
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                        >
                            <LoadingButton
                                type="submit"
                                loading={saving}
                                loadingIndicator="Saving..."
                                color="primary"
                                variant="contained"
                                disabled={!isValid}
                            >
                                Save
                            </LoadingButton>
                        </Stack>
                        <Box sx={{ mt: 2 }}>
                            <Stack direction="column" spacing={2}>
                                <Stack
                                    direction={{ xs: "column", sm: "row" }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}
                                >
                                    <CustomTextField
                                        values={values}
                                        onBlur={handleBlur}
                                        required={true}
                                        name="Code"
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                    />
                                    <CustomTextField
                                        values={values}
                                        onBlur={handleBlur}
                                        required={true}
                                        name="Name"
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                    />
                                    {!values?.Explode ? (
                                        <CustomTextField
                                            values={values}
                                            onBlur={handleBlur}
                                            required={true}
                                            name="CPTCode"
                                            label="CPT Code"
                                            handleChange={(e) => {
                                                handleChange(e);
                                                updateCPT(e);
                                            }}
                                            touched={touched}
                                            errors={errors}
                                            inputProps={{
                                                minLength: 5,
                                                maxLength: 5,
                                            }}
                                        />) : null}
                                    <CustomTextField
                                        values={values}
                                        onBlur={handleBlur}
                                        required={true}
                                        name="AMDCode"
                                        label="AMD Code"
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={"Explode"}
                                                checked={values?.["Explode"]}
                                                onChange={(e, checked) => {
                                                    handleChange(e);
                                                    if (checked) {
                                                        setCPTDetails(null)
                                                        setFieldValue("CPTCode", "")
                                                    }

                                                }}
                                            />
                                        }
                                        label={"Exploding Procedure"}
                                        name={"Explode"}
                                    />
                                </Stack>
                                {cptDetails !== null && !values?.Explode
                                    ? cptTable(setFieldValue)
                                    : null}

                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    options={modifiers}
                                    onChange={(_, mods) => setFieldValue("CPTModifiers", mods)}
                                    disableListWrap
                                    value={values?.CPTModifiers || []}
                                    getOptionLabel={(option) =>
                                        option.Code + ": " + option.Description
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.Code === value?.Code
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Modifiers"
                                            placeholder="Search for modifier"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                {values?.Explode ? (
                                    <>
                                        <InputLabel>Explodes To</InputLabel>
                                        <ProceduresTable
                                            rows={procedures}
                                            selected={explodesTo}
                                            setSelected={setExplodesTo}
                                        />
                                    </>
                                ) : null}
                                {error !== "" ? (
                                    <Alert severity="error">{error}</Alert>
                                ) : null}
                            </Stack>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ProcedureForm;
