
// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    BorderOutlined,
    BoxPlotOutlined,
    ChromeOutlined,
    DeploymentUnitOutlined,
    GatewayOutlined,
    MenuUnfoldOutlined,
    QuestionOutlined,
    SmileOutlined,
    StopOutlined,
    ClearOutlined,
    DingdingOutlined,
    FunnelPlotOutlined,
    HighlightOutlined,
    UserOutlined,
} from "@ant-design/icons";

// icons
const icons = {
    ChromeOutlined,
    MenuUnfoldOutlined,
    BoxPlotOutlined,
    StopOutlined,
    BorderOutlined,
    SmileOutlined,
    GatewayOutlined,
    QuestionOutlined,
    DeploymentUnitOutlined,
    DingdingOutlined,
    ClearOutlined,
    HighlightOutlined,
    FunnelPlotOutlined,
    UserOutlined,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
// Update name mapping in en.json

const queues = {
    id: "queues",
    title: <FormattedMessage id="queues" />,
    type: "group",
    children: [
        {
            id: "preprocessing",
            title: <FormattedMessage id="preprocessing" />,
            type: "item",
            url: "/preprocessing",
            icon: icons.FunnelPlotOutlined,
        },
        {
            id: "claim-scrubbing",
            title: <FormattedMessage id="claim-scrubbing" />,
            type: "item",
            url: "/claim-scrubbing",
            icon: icons.ClearOutlined,
        },
        {
            id: "submitted",
            title: <FormattedMessage id="submitted" />,
            type: "item",
            url: "/submitted",
            icon: icons.DingdingOutlined,
        },
        {
            id: "patients",
            title: <FormattedMessage id="patients" />,
            type: "item",
            url: "/patients",
            icon: icons.UserOutlined,

        },
    ],
};

const accountQueues = {
    id: "queues",
    title: <FormattedMessage id="queues" />,
    type: "group",
    children: [
        {
            id: "preprocessing",
            title: <FormattedMessage id="preprocessing" />,
            type: "item",
            url: "/preprocessing",
            icon: icons.FunnelPlotOutlined,
        },
        {
            id: "claim-scrubbing",
            title: <FormattedMessage id="claim-scrubbing" />,
            type: "item",
            url: "/claim-scrubbing",
            icon: icons.ClearOutlined,
        },
        {
            id: "submitted",
            title: <FormattedMessage id="submitted" />,
            type: "item",
            url: "/submitted",
            icon: icons.DingdingOutlined,
        },
    ],
};

export default queues;
export { accountQueues };
