// project import
import other from './other';
import queues, { accountQueues } from './queues';
import home from './home';
import setup from './setup';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [home, queues, setup, other]
};

const clientMenuItems = {
  items: [home, queues]
}

const accountMenuItems = {
  items: [accountQueues]
}

export default menuItems;
export { clientMenuItems, accountMenuItems };
