import React, { useState, useEffect } from "react";
import useAuth from 'hooks/useAuth';
import axios from "utils/axios";
import ClientsMappingTable from "components/ClientMappingTable";
import ProceduresTable from "components/ClientProceduresTable";
import { Box, Stack } from "@mui/system";
import ProcedureForm from "components/ProcedureForm";
import { Button } from "@mui/material";
import { User } from "types";

const ClientProcedures = () => {
    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState<number | null>(null);
    const [procedures, setProcedures] = useState([]);
    const [selectedProc, setSelectedProc] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const { user }: { user: User } = useAuth();

    useEffect(() => {
        const init = async () => {
            let response = await axios.get("/v1/clients");
            setClients(response.data);
        };

        init();
    }, [refresh]);

    useEffect(() => {
        if (user?.ClientFilter) {
            setClientId(user.ClientFilter)
        }
    }, [user])


    useEffect(() => {
        if (clientId === null || !Number.isInteger(Number(clientId))) {
            return;
        }

        const getClientProcedures = async () => {
            let response = await axios.get(`/v1/clients/${clientId}/procedures`);
            setProcedures(response.data);
        };

        getClientProcedures();
    }, [clientId, refresh]);

    console.log(selectedProc)

    if (!clientId) {
        return <ClientsMappingTable clients={clients} setClientId={setClientId} />
    } else if (create) {
        return <ProcedureForm procedureId={null} clientId={clientId} back={() => { setCreate(false); setRefresh(!refresh) }} />
    } else if (edit && selectedProc.length === 1) {
        return <ProcedureForm clientId={clientId} procedureId={selectedProc[0]} back={() => { setEdit(false); setRefresh(!refresh) }} />
    }
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Button variant="contained" onClick={() => setCreate(true)}>Create</Button>
                <Button variant="outlined" color="secondary" onClick={() => setEdit(true)}>Edit</Button>
            </Stack>
            <Box height={`calc(100vh - 425px)`}>
                <ProceduresTable selected={[]} rows={procedures} setSelected={setSelectedProc} disableCheckboxes={true} />
            </Box>
        </>
    )


}

export default ClientProcedures;
