import { lazy } from "react";

// project import
import MainLayout from "layout/MainLayout";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Mappings from "pages/extra-pages/mappings";
import ClaimScrubbingPage from "pages/extra-pages/presubmission";
import Ingest from "pages/extra-pages/ingest";

// pages routing
const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));
const MaintenanceError500 = Loadable(
    lazy(() => import("pages/maintenance/500"))
);
const MaintenanceUnderConstruction = Loadable(
    lazy(() => import("pages/maintenance/under-construction"))
);
const MaintenanceComingSoon = Loadable(
    lazy(() => import("pages/maintenance/coming-soon"))
);

// render - sample page
const SamplePage = Loadable(
    lazy(() => import("pages/extra-pages/sample-page"))
);
const PreProcessingPage = Loadable(
    lazy(() => import("pages/extra-pages/preprocessing"))
);
const SubmittedPage = Loadable(
    lazy(() => import("pages/extra-pages/submitted"))
);
const PatientsPage = Loadable(
    lazy(() => import("pages/extra-pages/patients"))
);
const Dashboard = Loadable(lazy(() => import("pages/extra-pages/dashboard")));
const Configuration = Loadable(
    lazy(() => import("pages/extra-pages/configurations"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    children: [
        {
            path: "/",
            element: (
                <AuthGuard>
                    <MainLayout disableBreadcrumbs={true} />
                </AuthGuard>
            ),
            children: [
                {
                    path: "dashboard",
                    element: <Dashboard />,
                },
                {
                    path: "ingest",
                    element: <Ingest />,
                },
                {
                    path: "preprocessing",
                    element: <PreProcessingPage />,
                },
                {
                    path: "claim-scrubbing",
                    element: <ClaimScrubbingPage />,
                },
                {
                    path: "submitted",
                    element: <SubmittedPage />,
                },
                {
                    path: "patients",
                    element: <PatientsPage />,
                },
                {
                    path: "configuration",
                    element: <Configuration />,
                },
                {
                    path: "mappings",
                    element: <Mappings />,
                },
                {
                    path: "sample-page",
                    element: <SamplePage />,
                },
            ],
        },
        {
            path: "/maintenance",
            element: <CommonLayout />,
            children: [
                {
                    path: "404",
                    element: <MaintenanceError />,
                },
                {
                    path: "500",
                    element: <MaintenanceError500 />,
                },
                {
                    path: "under-construction",
                    element: <MaintenanceUnderConstruction />,
                },
                {
                    path: "coming-soon",
                    element: <MaintenanceComingSoon />,
                },
            ],
        },
    ],
};

const ClientMainRoutes = {
    path: "/",
    children: [
        {
            path: "/",
            element: (
                <AuthGuard>
                    <MainLayout disableBreadcrumbs={true} />
                </AuthGuard>
            ),
            children: [
                {
                    path: "dashboard",
                    element: <Dashboard />,
                },
                {
                    path: "preprocessing",
                    element: <PreProcessingPage />,
                },
                {
                    path: "claim-scrubbing",
                    element: <ClaimScrubbingPage />,
                },
                {
                    path: "submitted",
                    element: <SubmittedPage />,
                },
                {
                    path: "patients",
                    element: <PatientsPage />,
                },
            ],
        },
    ],
};

const AccountMainRoutes = {
    path: "/",
    children: [
        {
            path: "/",
            element: (
                <AuthGuard>
                    <MainLayout disableBreadcrumbs={true} />
                </AuthGuard>
            ),
            children: [
                {
                    path: "preprocessing",
                    element: <PreProcessingPage />,
                },
                {
                    path: "claim-scrubbing",
                    element: <ClaimScrubbingPage />,
                },
                {
                    path: "submitted",
                    element: <SubmittedPage />,
                },
            ],
        },
    ],
};

export default MainRoutes;
export { ClientMainRoutes, AccountMainRoutes };
