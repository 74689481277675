import { Stack } from "@mui/material"

function StyledStack({ children, ...props }) {
    return (
        <Stack spacing={2} direction={{ sm: "column", md: "row" }} {...props}>
            {children}
        </Stack>
    )
}

export default StyledStack;
