import React, { useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';

function ConfirmDialog({ open, setOpen, onConfirm, title, subtext, children }) {
    console.log(children)

    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{subtext}</DialogContentText>
                {children}
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    color="success"
                    variant="contained"
                    onClick={onConfirm}>
                    <span>Confirm</span>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;
