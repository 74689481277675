import { useRoutes } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes, { AccountMainRoutes, ClientMainRoutes } from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { user } = useAuth();
  console.log('user', user)

  // @ts-ignore
  const isBillingUser = user?.Type === "Biller"
  const isAccountUser = user?.Accounts?.length > 0

  let routes = [LoginRoutes, ClientMainRoutes]

  if (isBillingUser) {
    routes = [LoginRoutes, MainRoutes];
  } else if (isAccountUser) {
    routes = [LoginRoutes, AccountMainRoutes];
  }
  // is client user with no accounts
  return useRoutes(routes)
}
