import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Menu,
    MenuItem,
    styled,
    alpha
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import ConfirmDialog from "./ConfirmDialog";


function AccessionOptionMenu({ disabled, open, setOpen, assignedToClient, setAction }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true)
    };

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));

    return (
        <>
            <Button
                onClick={handleClick}
                variant="contained"
                endIcon={<KeyboardArrowDown />}
                disabled={disabled}
            >
                Options
            </Button>
            <StyledMenu
                disabled={disabled}
                onClose={() => { setOpen(false); setAnchorEl(null) }}
                anchorEl={anchorEl}
                variant="contained"
                color="primary"
                open={open}
                aria-label="split button"
            >
                <MenuItem onClick={() => { setAction("rerun"); setOpen(false) }}>Rerun Stage</MenuItem>
                <MenuItem onClick={() => { setAction("bypass"); setOpen(false) }}>Bypass Stage</MenuItem>
                {assignedToClient ? <MenuItem onClick={() => { setAction("unassignFromClient"); setOpen(false) }}>Unassign From Client</MenuItem> :
                    <MenuItem onClick={() => { setAction("assignToClient"); setOpen(false) }}>Assign To Client</MenuItem>}
                <MenuItem onClick={() => { setAction("markBilled"); setOpen(false) }}>Mark Billed</MenuItem>
            </StyledMenu>
        </>
    )

}

export default AccessionOptionMenu;
