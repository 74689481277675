// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    BorderOutlined,
    BoxPlotOutlined,
    ChromeOutlined,
    DeploymentUnitOutlined,
    GatewayOutlined,
    MenuUnfoldOutlined,
    QuestionOutlined,
    SmileOutlined,
    StopOutlined,
    ClearOutlined,
    DingdingOutlined,
    FunnelPlotOutlined,
        SettingOutlined,
        TableOutlined,
        SubnodeOutlined,
} from "@ant-design/icons";

// icons
const icons = {
    ChromeOutlined,
    MenuUnfoldOutlined,
    BoxPlotOutlined,
    StopOutlined,
    BorderOutlined,
    SmileOutlined,
    GatewayOutlined,
    QuestionOutlined,
    DeploymentUnitOutlined,
    DingdingOutlined,
    ClearOutlined,
    FunnelPlotOutlined,
        SettingOutlined,
        TableOutlined,
        SubnodeOutlined,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
// Update name mapping in en.json

const setup = {
    id: "setup",
    title: <FormattedMessage id="setup" />,
    type: "group",
    children: [
        {
            id: "mappings",
            title: <FormattedMessage id="mappings" />,
            type: "item",
            url: "/mappings",
            icon: icons.TableOutlined,
        },
        {
            id: "configuration",
            title: <FormattedMessage id="configuration" />,
            type: "item",
            url: "/configuration",
            icon: icons.SettingOutlined,
        },
        {
            id: "integrations",
            title: <FormattedMessage id="integrations" />,
            type: "item",
            url: "/integrations",
            icon: icons.SubnodeOutlined,
        },
    ],
};

export default setup;
