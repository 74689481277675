import { useState, useEffect, createContext } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, Form, useFormikContext } from "formik";
import { Button, TextField, FormLabel, Switch, FormControlLabel, Stack } from "@mui/material";
import * as Yup from "yup";

import axios from "utils/axios";
import StyledStack from "./StyledStack";
import CustomTextField, { CustomSelectField } from "components/CustomTextField";
import PatientInsuranceFields from "./PatientInsuranceFields";
import { ErrorAlert } from "./Alerts";
import Loader from "./Loader";
import { LoadingOutlined } from "@ant-design/icons";

function EligibilityResponseLogic({ eligResponses }) {
    const { setFieldValue, values } =
        useFormikContext();

    useEffect(() => {
        // perform updating logic based on eligibility responses
        console.log(eligResponses)

    }, [eligResponses])

    return <></>
}

function PatientForm({ id, refresh = () => { } }) {
    const [eligResponses, setEligResponses] = useState({})
    const [edit, setEdit] = useState(true)
    const [error, setError] = useState(null)
    const [patient, setPatient] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!id) {
            return
        }

        const getDetail = async () => {
            try {
                setLoading(true)
                const response = await axios.get(`/v1/patients/${id}`);
                const patient = response.data
                patient.DOB = dayjs.utc(patient.DOB)
                for (let i = 0; i < patient.PatientInsurances.length; i++) {
                    if (patient.PatientInsurances[i].Effective) {
                        patient.PatientInsurances[i].Effective = dayjs.utc(patient.PatientInsurances[i].Effective)
                    }
                    if (patient.PatientInsurances[i].Discontinue) {
                        patient.PatientInsurances[i].Discontinue = dayjs.utc(patient.PatientInsurances[i].Discontinue)
                    }
                    if (patient.PatientInsurances[i].Subscriber.DOB) {
                        patient.PatientInsurances[i].Subscriber.DOB = dayjs.utc(patient.PatientInsurances[i].Subscriber.DOB)
                    }
                }
                // patient.DOB = patient.DOB.slice(0, 10)
                setPatient(patient)
            } catch (e) {
                setError("Failed to load patient.")
            } finally {
                setLoading(false)
            }
        }

        getDetail()
    }, [id])


    const savePatient = (values) => {
        const data = { ...values }

        for (const [key, value] of Object.entries(eligResponses)) {
            try {
                data.PatientInsurances[key].EligibilityResponses.push(value)
            } catch (e) {
                console.log(e)
            }
        }

        data.ClientID = patient.ClientID
        console.log(data)

        const save = async () => {
            try {
                const response = await axios.post(`/v1/patients/${patient.ID}`, data)
            } catch (e) {
                console.log(e)
                setError("Failed to save patient.")
            } finally {
                setEdit(false)
                refresh()
            }
        }

        save()

    }

    const addressSchema = Yup.object().shape({
        Address1: Yup.string().required(),
        Address2: Yup.string().nullable(),
        City: Yup.string().required(),
        State: Yup.string().required(),
        ZipCode: Yup.string().required(),
    });
    const notRequiredAddressSchema = Yup.object().shape({
        Address1: Yup.string(),
        Address2: Yup.string(),
        City: Yup.string(),
        State: Yup.string(),
        ZipCode: Yup.string(),
    });

    const validationSchema = Yup.object().shape({
        FirstName: Yup.string().required(),
        LastName: Yup.string().required(),
        Address: addressSchema,
        InvoiceAddress: notRequiredAddressSchema,
        InvoiceEmail: Yup.string().email().required(),
        NPI: Yup.number(),
        FEIN: Yup.string(),
    });

    const handleSubmit = (values) => {
        console.log(values)
    }

    const getSex = (values) => {
        if (values?.Sex) {
            switch (values?.Sex[0]) {
                case "M":
                    return "Male";
                case "F":
                    return "Female";
                case "U":
                    return "Unknown";
                default:
                    return null;
            }
        }
        return null
    }

    if (!patient) {
        return <LoadingOutlined />
    }

    return (
        <FormContext.Provider value={edit}>
            <Stack direction="row" spacing={1} justifyContent="right">
                <FormControlLabel control={<Switch checked={edit} onChange={(_, v) => setEdit(v)} />} label="Edit" />
            </Stack>
            <Formik
                initialValues={patient}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    errors,
                    setFieldValue,
                    touched,
                }) => (
                    < Form >
                        <StyledStack m={1} direction="column">
                            <StyledStack>
                                <EligibilityResponseLogic eligResponses={eligResponses} />
                                {console.log('formik values', values)}
                                <CustomTextField
                                    name="FirstName"
                                    label="First Name"
                                    required={true}
                                    disabled={!edit}
                                />
                                <CustomTextField
                                    name="LastName"
                                    label="Last Name"
                                    required={true}
                                    disabled={!edit}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disableFuture
                                        label="Date of Birth"
                                        name="DOB"
                                        value={values.DOB}
                                        disabled={!edit}
                                        onChange={(date) => {
                                            setFieldValue("DOB", date)
                                        }
                                        }
                                        helperText={
                                            touched.DOB &&
                                            Boolean(errors.DOB)
                                        }
                                        error={
                                            touched.DOB &&
                                            Boolean(errors.DOB)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                size="small"
                                                helperText="mm/dd/yyyy"
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                <CustomSelectField
                                    name="Sex"
                                    label="Sex"
                                    required
                                    disabled={!edit}
                                    value={getSex(values)}
                                    sx={{ minWidth: "100px" }}
                                    options={[
                                        { label: "Male", value: "Male" },
                                        { label: "Female", value: "Female" },
                                        { label: "Unknown", value: "Unknown" },
                                    ]}
                                />
                                <CustomTextField
                                    name="SSN"
                                    label="SSN"
                                    disabled={!edit}
                                />
                            </StyledStack>
                            <FormLabel>Contact</FormLabel>
                            <StyledStack>
                                <CustomTextField
                                    name="Phone"
                                    label="Phone"
                                    disabled={!edit}
                                />
                                <CustomTextField
                                    name="Email"
                                    label="Email"
                                    disabled={!edit}
                                    type="email"
                                />
                            </StyledStack>
                            <FormLabel>Address</FormLabel>
                            <StyledStack>
                                <CustomTextField
                                    name="Address.Address1"
                                    label="Address 1"
                                    required={true}
                                    value={values?.Address?.Address1}
                                    disabled={!edit}
                                />
                                <CustomTextField
                                    name="Address.Address2"
                                    label="Address 2"
                                    value={values?.Address?.Address2}
                                    disabled={!edit}
                                />
                                <CustomTextField
                                    name="Address.City"
                                    label="City"
                                    required={true}
                                    value={values?.Address?.City}
                                    disabled={!edit}
                                />
                                <CustomTextField
                                    name="Address.State"
                                    label="State"
                                    required={true}
                                    value={values?.Address?.State}
                                    disabled={!edit}
                                />
                                <CustomTextField
                                    name="Address.ZipCode"
                                    label="ZipCode"
                                    required={true}
                                    value={values?.Address?.ZipCode}
                                    disabled={!edit}
                                />
                            </StyledStack>
                            <PatientInsuranceFields
                                patientInsurances={values?.PatientInsurances}
                                patient={values}
                                eligResponses={eligResponses}
                                setEligResponses={setEligResponses} />
                            <ErrorAlert error={error} />
                            <Stack direction="row" justifyContent="flex-end">
                                {edit && <Button variant="contained" color="success" onClick={() => savePatient(values)}>Save</Button>}
                            </Stack>
                        </StyledStack>
                    </Form>
                )
                }
            </Formik >
        </FormContext.Provider >
    )
}

export default PatientForm;
export const FormContext = createContext(false);
