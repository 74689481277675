import React from "react";
import { Grid } from "@mui/material";
import { Accession } from "types";
import {
    AccessionClientAccountSummary,
    AccessionProviderSummary,
    AccessionPatientSummary,
    AccessionPayerSummary,
    AccessionProcedureSummary,
    AccessionDiagnosisSummary,
    AccessionAttachmentsSummary,
    AccessionNotesSummary,
    AccessionStageSummary,
} from "./AccessionSummaryPanels";

function AccessionSummary({ accession }: { accession: Accession | null }) {
    const [refresh, setRefresh] = React.useState(false);

    console.log(refresh)

    if (!accession) {
        return <></>
    }

    return (
        <Grid container spacing={3} rowSpacing={4}>
            <Grid item xs={12} sm={3} alignContent="center">
                <AccessionClientAccountSummary clientId={accession?.ClientID} clientAccountId={accession?.ClientAccountID} />
            </Grid>
            <Grid item xs={12} sm={3} alignContent="center">
                <AccessionProviderSummary providerId={accession?.ProviderID} />
            </Grid>
            <Grid item xs={12} sm={3} alignContent="center">
                <AccessionPatientSummary patientId={accession?.PatientID} />
            </Grid>
            <Grid item xs={12} sm={3} alignContent="center">
                <AccessionPayerSummary accessionId={accession?.ID} patientInsuranceId={accession?.PatientInsuranceID} patientId={accession?.PatientID} />
            </Grid>
            <Grid item xs={12} sm={4} alignContent="center">
                <AccessionProcedureSummary accessionId={accession?.ID} refresh={refresh} />
            </Grid>
            <Grid item xs={12} sm={4} alignContent="center">
                <AccessionDiagnosisSummary accessionId={accession?.ID} setRefresh={setRefresh} />
            </Grid>
            <Grid item xs={12} sm={4} alignContent="center">
                <AccessionStageSummary accessionId={accession?.ID} />
            </Grid>
            <Grid item xs={12} sm={4} alignContent="center">
                <AccessionAttachmentsSummary accessionId={accession?.ID} />
            </Grid>
            <Grid item xs={12} sm={12} alignContent="center">
                <AccessionNotesSummary accessionId={accession?.ID} />
            </Grid>
        </Grid>
    )
}

export default AccessionSummary;
