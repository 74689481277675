// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    BorderOutlined,
    BoxPlotOutlined,
    ChromeOutlined,
    DeploymentUnitOutlined,
    GatewayOutlined,
    MenuUnfoldOutlined,
    QuestionOutlined,
    SmileOutlined,
    StopOutlined,
    ClearOutlined,
    DingdingOutlined,
    FunnelPlotOutlined,
} from "@ant-design/icons";

// icons
const icons = {
    ChromeOutlined,
    MenuUnfoldOutlined,
    BoxPlotOutlined,
    StopOutlined,
    BorderOutlined,
    SmileOutlined,
    GatewayOutlined,
    QuestionOutlined,
    DeploymentUnitOutlined,
    DingdingOutlined,
    ClearOutlined,
    FunnelPlotOutlined,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
// Update name mapping in en.json

const other = {
    id: "other",
    title: <FormattedMessage id="others" />,
    type: "group",
    children: [
        {
            id: "sample-page",
            title: <FormattedMessage id="sample-page" />,
            type: "item",
            url: "/sample-page",
            icon: icons.ChromeOutlined,
        },
        {
            id: "documentation",
            title: <FormattedMessage id="documentation" />,
            type: "item",
            url: "https://links.codedthemes.com/BQFrl",
            icon: icons.QuestionOutlined,
            external: true,
            target: true,
            chip: {
                label: "gitbook",
                color: "secondary",
                size: "small",
            },
        },
        {
            id: "roadmap",
            title: <FormattedMessage id="roadmap" />,
            type: "item",
            url: "https://links.codedthemes.com/RXnKQ",
            icon: icons.DeploymentUnitOutlined,
            external: true,
            target: true,
        },
    ],
};

export default other;
