import React, { useState, useEffect } from "react"
import { Chip, Table, TableContainer, Typography, TableRow, TableCell, TableBody, TableHead, Button, IconButton, Stack, Dialog, DialogContent, DialogActions, DialogTitle, CircularProgress, TextField } from "@mui/material"

import axios from "utils/axios"
import { ErrorAlert } from "./Alerts"
import LoadingCircle from "./LoadingCircle"
import { formatAddress, formatDate } from "utils/formatting"
import { financialClassIndex, stageIndex } from "utils/xrefs"
import { Edit } from "@mui/icons-material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import CloseIcon from "@mui/icons-material/Close";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { EditOutlined } from "@ant-design/icons"
import DiagnosisCodePicker from "./DiagnosisCodePicker"

dayjs.extend(utc);

const simpleGet = async ({ setLoading, setData, setError, url }) => {
    try {
        setLoading(true)
        const response = await axios.get(url);
        if (response.status !== 200) {
            setError("Failed to load data.")
            return
        }
        setData(response.data)
    } catch (e) {
        setError("Failed to load data.")
    } finally {
        setLoading(false)
    }
}

function AccessionClientAccountSummary({ clientId, clientAccountId }) {
    const [error, setError] = useState(null)
    const [client, setClient] = useState(null)
    const [account, setAccount] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!clientId) {
            return
        }

        simpleGet({ setLoading, setData: setClient, setError, url: `/v1/clients/${clientId}` })
        simpleGet({ setLoading, setData: setAccount, setError, url: `/v1/clients/accounts/${clientAccountId}` })
    }, [clientId, clientAccountId])


    if (loading) { return <LoadingCircle /> }
    if (error) { return <ErrorAlert error={error} /> }
    if (!client || !account) { return <></> }

    return (
        <>
            <Typography sx={{ textAlign: "center" }} variant="h4">Client</Typography>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">{client?.Name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Account</TableCell>
                            <TableCell align="right">{account?.Name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell align="right">{formatAddress(
                                account?.Address?.Address1,
                                account?.Address?.Address2,
                                account?.Address?.City,
                                account?.Address?.State,
                                account?.Address?.ZipCode,
                            )}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>POS</TableCell>
                            <TableCell align="right">{account?.POS}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

function AccessionProviderSummary({ providerId }) {
    const [error, setError] = useState(null)
    const [provider, setProvider] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!providerId) {
            return
        }

        simpleGet({ setLoading, setData: setProvider, setError, url: `/v1/providers/${providerId}` })
    }, [providerId])


    if (loading) { return <LoadingCircle /> }
    if (error) { return <ErrorAlert error={error} /> }
    if (!provider) { return <></> }

    return (
        <>
            <Typography sx={{ textAlign: "center" }} variant="h4">Provider</Typography>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>NPI</TableCell>
                            <TableCell align="right">{provider?.NPI}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">{provider?.FirstName} {provider?.LastName}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

function AccessionPatientSummary({ patientId }) {
    const [error, setError] = useState(null)
    const [patient, setpatient] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!patientId) {
            return
        }

        simpleGet({ setLoading, setData: setpatient, setError, url: `/v1/patients/${patientId}` })
    }, [patientId])


    if (loading) { return <LoadingCircle /> }
    if (error) { return <ErrorAlert error={error} /> }
    if (!patient) { return <></> }

    return (
        <>
            <Typography sx={{ textAlign: "center" }} variant="h4">Patient</Typography>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">{patient?.FirstName} {patient?.LastName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>DOB</TableCell>
                            <TableCell align="right">{formatDate(patient?.DOB)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sex</TableCell>
                            <TableCell align="right">{formatDate(patient?.Sex)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell align="right">{formatAddress(
                                patient?.Address?.Address1,
                                patient?.Address?.Address2,
                                patient?.Address?.City,
                                patient?.Address?.State,
                                patient?.Address?.ZipCode,
                            )}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

function PayerPicker({ accessionId, patientInsurances, open, setOpen }) {
    const [selected, setSelected] = useState([])

    const savePayer = async () => {
        if (selected.length !== 1) {
            return
        }

        try {
            const response = await axios.post(`/v1/accessions/${accessionId}/payer`, { patientInsuranceId: selected[0] })
            if (response.status !== 200) {
                console.log("Failed to save payer")
            }
            setOpen(false)
        }
        catch (e) {
            console.log(e)
        } finally {
        }
    }

    // @ts-ignore
    const columns = [
        { field: 'ID', headerName: 'ID', hide: true },
        // @ts-ignore
        { field: 'Payer.Name', headerName: 'Name', width: 100, valueGetter: (params) => params.row.Payer.Name },
        { field: 'MemberID', headerName: 'Member ID', width: 100 },
        { field: 'GroupID', headerName: 'Group ID', width: 100 },
        { field: 'Relationship', headerName: 'Relationship', width: 75 },
        {
            field: 'Effective', headerName: 'Effective', width: 150,
            // @ts-ignore
            valueGetter: ({ value }) => value && dayjs.utc(value).toDate(),
        },
        {
            field: 'Discontinue', headerName: 'Discontinue', width: 150,
            // @ts-ignore
            valueGetter: ({ value }) => value && dayjs.utc(value).toDate()
        },
        { field: 'Enabled', headerName: 'Enabled', width: 150 },
        { field: 'Eligible', headerName: 'Eligible', width: 150 },
        { field: 'Approved', headerName: 'Approved', width: 150 },
    ]

    return (
        <Dialog open={open}>
            <DialogTitle>Payer Picker</DialogTitle>
            <DialogContent>
                <DataGridPro
                    getRowId={(row) => row.ID}
                    rows={patientInsurances}
                    // @ts-ignore
                    columns={columns}
                    rowSelectionModel={selected}
                    // @ts-ignore
                    onRowSelectionModelChange={setSelected}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={() => savePayer()}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

function AccessionPayerSummary({ accessionId, patientInsuranceId, patientId }) {
    const [error, setError] = useState(null)
    const [patientInsurance, setPatientInsurance] = useState(null)
    const [insurancePicker, setInsurancePicker] = useState(false)
    const [patient, setPatient] = useState(null)
    const [loading, setLoading] = useState(true)
    console.log("id", patientInsuranceId)

    useEffect(() => {
        if (!patientInsuranceId) {
            return
        }

        simpleGet({ setLoading, setData: setPatientInsurance, setError, url: `/v1/patientInsurances/${patientInsuranceId}` })
    }, [patientInsuranceId])

    useEffect(() => {
        if (!insurancePicker) {
            return
        }

        simpleGet({ setLoading, setData: setPatient, setError, url: `/v1/patients/${patientId}` })
    }, [insurancePicker, patientId])


    if (loading) { return <LoadingCircle /> }
    if (error) { return <ErrorAlert error={error} /> }
    if (!patientInsurance) { return <></> }

    return (
        <>
            {/* @ts-ignore */}
            <PayerPicker accessionId={accessionId} patientInsurances={patient?.PatientInsurances || []} open={insurancePicker} setOpen={setInsurancePicker} />
            <Stack direction="row" spacing={1}>
                <Typography sx={{ textAlign: "center" }} variant="h4">Payer</Typography>
                <IconButton onClick={() => setInsurancePicker(true)}><Edit /></IconButton>
            </Stack>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">{patientInsurance?.Payer?.Name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Member ID</TableCell>
                            <TableCell align="right">{patientInsurance?.MemberID}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Financial Class</TableCell>
                            <TableCell align="right">{financialClassIndex[patientInsurance?.Payer?.FinancialClass]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>AMD Code</TableCell>
                            <TableCell align="right">{patientInsurance?.Payer?.AMDPayerCode}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

function AccessionProcedureSummary({ accessionId, refresh }) {
    const [error, setError] = useState(null)
    const [procedures, setProcedures] = useState([])
    const [loading, setLoading] = useState(true)
    const [localRefresh, setLocalRefresh] = useState(true)

    useEffect(() => {
        if (!accessionId) {
            return
        }

        simpleGet({ setLoading, setData: setProcedures, setError, url: `/v1/accessions/${accessionId}/clientProcedures` })
    }, [accessionId, refresh, localRefresh])

    const handleDeleteProcedure = async (procedureId) => {
        try {
            setLoading(true)
            await axios.delete(`/v1/accessions/${accessionId}/clientProcedures/${procedureId}`)
            setLocalRefresh((refresh) => !refresh)
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }

    }

    if (loading) { return <LoadingCircle /> }
    if (error) { return <ErrorAlert error={error} /> }
    if (!procedures || procedures.length < 1) { return <></> }

    return (
        <>
            <Typography sx={{ textAlign: "center" }} variant="h4">Procedures</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell size="small" sx={{ padding: "0" }}>
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>CPT</TableCell>
                        <TableCell>AMD Code</TableCell>
                        <TableCell align="right">DC 1</TableCell>
                        <TableCell align="right">DC 2</TableCell>
                        <TableCell align="right">DC 3</TableCell>
                        <TableCell align="right">DC 4</TableCell>
                    </TableHead>
                    <TableBody>
                        {procedures.map((procedure) => (
                            <>
                                <TableRow>
                                    <TableCell size="small" sx={{ padding: "0" }}>
                                        <Button onClick={() => handleDeleteProcedure(procedure?.ID)}
                                            size="small"
                                            sx={{ minWidth: "12px" }}>
                                            <CloseIcon color="error" />
                                        </Button>
                                    </TableCell>

                                    <TableCell>{procedure?.ClientProcedure?.Name}</TableCell>
                                    <TableCell >{procedure?.ClientProcedure?.CPTCode}</TableCell>
                                    <TableCell >{procedure?.ClientProcedure?.AMDCode}</TableCell>
                                    <TableCell align="right">{procedure?.DiagnosisCode1Code}</TableCell>
                                    <TableCell align="right">{procedure?.DiagnosisCode2Code}</TableCell>
                                    <TableCell align="right">{procedure?.DiagnosisCode3Code}</TableCell>
                                    <TableCell align="right">{procedure?.DiagnosisCode4Code}</TableCell>
                                </TableRow>
                            </>
                        )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

function AccessionDiagnosisSummary({ accessionId, setRefresh }) {
    const [error, setError] = useState(null)
    const [diagnosisCodes, setDiagnosisCodes] = useState([])
    const [rawDiagnosisCodes, setRawDiagnosisCodes] = useState("")
    const [loading, setLoading] = useState(true)
    const [edit, setEdit] = useState(false)
    const [localRefresh, setLocalRefresh] = useState(false)


    useEffect(() => {
        if (!rawDiagnosisCodes) {
            return
        }

        setDiagnosisCodes(rawDiagnosisCodes.map((dg) => ({ Code: dg.Code, Description: dg.Description })));
    }, [rawDiagnosisCodes])

    useEffect(() => {
        if (!accessionId) {
            return
        }

        simpleGet({ setLoading, setData: setRawDiagnosisCodes, setError, url: `/v1/accessions/${accessionId}/diagnosisCodes` })
    }, [accessionId, localRefresh])

    const handleSave = async () => {
        console.log("save")
        try {
            const data = { DiagnosisCodes: diagnosisCodes }
            await axios.post(`/v1/accessions/${accessionId}/diagnosisCodes`, data)
            setEdit(false)
            setRefresh((refresh) => !refresh)
        } catch (e) {
            console.error(e)
        }
    }


    if (loading) { return <LoadingCircle /> }
    if (error) { return <ErrorAlert error={error} /> }

    return (
        <>
            <Dialog open={edit} onClose={() => setEdit(false)}>
                <DialogContent>

                    <DiagnosisCodePicker codes={diagnosisCodes} setCodes={setDiagnosisCodes} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setEdit(false); setLocalRefresh(!localRefresh) }}>Close</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
            <Stack direction="row" justifyContent={"center"} alignItems="center">
                <Typography sx={{ textAlign: "center" }} variant="h4">Diagnosis Codes</Typography>
                <IconButton onClick={() => setEdit(!edit)}><EditOutlined /></IconButton>
            </Stack>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell>Code</TableCell>
                        <TableCell>Description</TableCell>
                    </TableHead>
                    <TableBody>
                        {diagnosisCodes.map((dc) => (
                            <>
                                <TableRow>
                                    <TableCell>{dc?.Code}</TableCell>
                                    <TableCell align="right">{dc?.Description}</TableCell>
                                </TableRow>
                            </>
                        )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

function AccessionStageSummary({ accessionId }) {
    const [error, setError] = useState(null)
    const [updateError, setUpdateError] = useState(null)
    const [stages, setStages] = useState([])
    const [loading, setLoading] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [updating, setUpdating] = useState(false)

    useEffect(() => {
        if (!accessionId) {
            return
        }

        simpleGet({ setLoading, setData: setData, setError, url: `/v1/accessions/${accessionId}/stages` })
    }, [accessionId, refresh])

    const prioritySort = (a, b) => {
        return a > b
    }

    const setData = (data) => {
        setStages(data.sort(prioritySort));
    }

    if (loading) { return <LoadingCircle /> }
    if (error) { return <ErrorAlert error={error} /> }
    if (!stages || stages.length < 1) { return <></> }

    const getStageStatus = (processed, error, bypassed, bypassedBy) => {
        if (processed && error?.Code === 0) {
            return <Chip color="success" label="Completed" />
        } else if (processed && error?.Code !== 0) {
            return <Chip color="warning" label="Completed with Errors" />
        } else if (bypassed) {
            return (
                <Stack direction="column" spacing={1}>
                    <Chip color="warning" label="Bypassed" />
                    {bypassedBy.Email}
                </Stack>
            )
        } else if (!processed && error?.Code !== 0) {
            return <Chip color="error" label={error?.Description} />
        }
    }

    const updateStage = (stageLogId, stageId, action) => {
        const data = {
            ID: stageLogId,
            StageID: stageId, Action: action
        }

        const update = async () => {
            try {
                setUpdating(true)
                await axios.post(`/v1/accessions/${accessionId}/stages`, data)
                setUpdateError(null)
                setRefresh(!refresh)
            } catch (e) {
                setUpdateError("Failed to update stage")
            } finally {
                setUpdating(false)
            }
        }

        update()
    }

    return (
        <>
            <Typography sx={{ textAlign: "center" }} variant="h4">Stages</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell>Stage</TableCell>
                        <TableCell>Priority</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Detail</TableCell>
                        <TableCell>Action</TableCell>
                    </TableHead>
                    <TableBody>
                        {stages.map((stage) => (
                            <>
                                <TableRow>
                                    <TableCell>{stageIndex[stage?.StageID]}</TableCell>
                                    <TableCell align="center">{stage?.Priority}</TableCell>
                                    <TableCell align="center">{getStageStatus(stage?.Processed, stage?.Error, stage?.Bypassed, stage?.BypassedBy)}</TableCell>
                                    <TableCell align="center">{stage?.ErrorDetail}</TableCell>
                                    <TableCell align="right">
                                        <Stack direction="row" spacing={1}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => updateStage(stage?.ID, stage?.StageID, "rerun")}
                                            >Rerun</Button><Button
                                                variant="contained"
                                                color="warning"
                                                onClick={() => updateStage(stage?.ID, stage?.StageID, "bypass")}
                                            >Bypass</Button></Stack></TableCell>
                                </TableRow>
                            </>
                        )
                        )}
                    </TableBody>
                </Table>
                <ErrorAlert error={updateError} />
            </TableContainer>
        </>
    )

}

function AccessionAttachmentsSummary({ accessionId }) {
    const [error, setError] = useState(null)
    const [attachments, setAttachments] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!accessionId) {
            return
        }

        simpleGet({ setLoading, setData: setAttachments, setError, url: `/v1/accessions/${accessionId}/attachments` })
    }, [accessionId])


    if (loading) { return <LoadingCircle /> }
    if (error) { return <ErrorAlert error={error} /> }
    if (!attachments || attachments.length < 1) { return <></> }

    return (
        <>
            <Typography sx={{ textAlign: "center" }} variant="h4">Attachments</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                    </TableHead>
                    <TableBody>
                        {attachments.map((att) => (
                            <>
                                <TableRow>
                                    <TableCell>{att?.Name}</TableCell>
                                    <TableCell align="right">{att?.Type}</TableCell>
                                </TableRow>
                            </>
                        )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

function AccessionNotesSummary({ accessionId }) {
    const [error, setError] = useState(null)
    const [notes, setNotes] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!accessionId) {
            return
        }

        simpleGet({ setLoading, setData: setNotes, setError, url: `/v1/accessions/${accessionId}/notes` })
    }, [accessionId])


    if (loading) { return <LoadingCircle /> }
    if (error) { return <ErrorAlert error={error} /> }
    if (!notes || notes.length < 1) { return <></> }

    return (
        <>
            <Typography sx={{ textAlign: "center" }} variant="h4">Notes</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell>Created At</TableCell>
                        <TableCell>Created By</TableCell>
                        <TableCell>Note</TableCell>
                    </TableHead>
                    <TableBody>
                        {notes.map((note) => (
                            <>
                                <TableRow>
                                    <TableCell>{formatDate(note?.CreatedAt)}</TableCell>
                                    <TableCell>{note?.AddedBy?.Email}</TableCell>
                                    <TableCell align="right">{note?.Note}</TableCell>
                                </TableRow>
                            </>
                        )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}


export {
    AccessionClientAccountSummary,
    AccessionProviderSummary,
    AccessionPatientSummary,
    AccessionPayerSummary,
    AccessionProcedureSummary,
    AccessionDiagnosisSummary,
    AccessionAttachmentsSummary,
    AccessionNotesSummary,
    AccessionStageSummary,
};
