import React, { useEffect, useState } from "react";
import axios from "utils/axios";
import { Table, TableCell, TableHead, TableBody, TableRow, TableContainer } from "@mui/material";
import { ErrorAlert } from "./Alerts";
import { FormatDateTime } from "utils/formatting";

import { EventLog } from "types";

function AccessionHistory({ accessionId, patientId, refresh }: { accessionId: number, patientId?: number, refresh: boolean }) {
    const [history, setHistory] = useState<EventLog[]>([])
    const [error, setError] = useState<null | string>()

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axios.get(`/v1/accessions/${accessionId}/history`)
                if (response.status === 200) {
                    setHistory((history) => [...history, ...response.data])
                    setError(null)
                } else {
                    throw new Error("failed to load history")
                }

                if (patientId) {
                    const patientResponse = await axios.get(`/v1/patients/${patientId}/history`)
                    if (patientResponse.status === 200) {
                        setHistory((history) => [...history, ...patientResponse.data])
                        setError(null)
                    } else {
                        throw new Error("failed to load patient history")
                    }
                }
            } catch {
                setError("Failed to load history")
            }
        }

        init()

    }, [accessionId, patientId, refresh])


    return (
        <>
            <ErrorAlert error={error} />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell>Timestamp</TableCell>
                        <TableCell>Event</TableCell>
                    </TableHead>
                    <TableBody>
                        {history.map((event: EventLog) => (
                            <TableRow>
                                <TableCell>{FormatDateTime(event.CreatedAt)}</TableCell>
                                <TableCell>{event.String}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default AccessionHistory;
