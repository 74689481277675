import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import { TabPanel } from "@mui/lab";

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};


function CustomTabs({ value, setValue, tabs }) {
    return (
        <TabContext value={value}>
            <Tabs
                value={value}
                onChange={(_, v) => setValue(v)}
                aria-label="basic tabs example"
            >
                {tabs.map((tab, index) => (<Tab label={tab.label} {...a11yProps(index)} />))}
            </Tabs>
            {tabs.map((tab, index) => (
                <TabPanel value={index} index={index} sx={{ height: "100%" }}>
                    {tab.component}
                </TabPanel>
            ))}
        </TabContext>
    )

}

export default CustomTabs;
