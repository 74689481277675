const FinancialClass = {
    Medicare: 0,
    Medicaid: 1,
    Commercial: 2,
    SelfPay: 3
};

const FeeScheduleTypes = {
    SelfPay: 0,
    ClientBill: 1,
    Insurance: 2
}

const FeeScheduleNames = {
    0: "Self Pay",
    1: "Client Bill",
    2: "Insurance"
}


export default FinancialClass;
export { FeeScheduleTypes, FeeScheduleNames };
