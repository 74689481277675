import { LoadingOutlined } from "@ant-design/icons";

function LoadingCircle() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <LoadingOutlined style={{ fontSize: "32px" }} />
        </div>
    )
}

export default LoadingCircle;
