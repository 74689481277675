import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { Dispatch, useState } from "react";
import { ErrorAlert } from "./Alerts";
import axios from "utils/axios";

function AddNote({ id, open, setOpen }: { id: number, open: boolean, setOpen: Dispatch<boolean> }) {
    const [note, setNote] = useState("")
    const [error, setError] = useState<null | string>(null)

    const handleSave = async () => {
        try {
            const response = await axios.post(`/v1/accessions/${id}/notes`, { Note: note })
            if (response.status === 200) {
                setNote("")
                setError(null)
                setOpen(false)
            } else {
                throw new Error("failed to save note")
            }
        } catch {
            setError("Failed to save note")
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Add Note</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ m: 2, minWidth: "400px" }}
                    multiline
                    rows={3}
                    label="Note"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />

            </DialogContent>
            <ErrorAlert error={error} />
            <DialogActions>
                <Button onClick={() => setOpen(false)} variant="contained" color="secondary">Cancel</Button>
                <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )

}

export default AddNote;
